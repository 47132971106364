import { FILTER, transformErrorResponse } from "@/app/constants/apiConstants"
import { apiSlice } from "@/app/redux/apiSlice"
import { IFilterGroup } from "@/interface/filters/filterGroup"
import { IFilterResponse, IFilterSearchParams, IFilterValue } from "@/interface/filters/filterValueSearch"

const filtersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFilters: builder.query<IFilterGroup[], void>({
      query: () => ({ url: FILTER.GET_ALL_FILTERS }),
      transformResponse: (response: { data: IFilterGroup[] }) => response.data,
      transformErrorResponse
    }),
    searchFilterValues: builder.query<IFilterResponse<IFilterValue>, IFilterSearchParams>({
      query: (params) => ({
        url: FILTER.GET_FILTER_VALUES,
        params
      })
    })
  })
})

export const { useGetFiltersQuery, useLazySearchFilterValuesQuery } = filtersApiSlice
