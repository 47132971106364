import useDocumentTitle from "@/app/hooks/documentTitleHook"
import { useAppSelector } from "@/app/hooks/reduxHooks"
import { buildSearchUrl } from "@/app/utils/searchUtils"
import { Avatar } from "@/components/ui/avatar"
import { Badge } from "@/components/ui/badge"
import { Card, CardContent } from "@/components/ui/card"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { DataTableColumn } from "@/interface/searchTable/components"
import { ContactAttributes, SearchApiResponse } from "@/interface/searchTable/search"
import { Building2, Globe, User2 } from "lucide-react"
import { useEffect, useMemo, useState } from "react"
import LinkedinIcon from "../../static/media/icons/linkedin_icon.svg?react"
import { selectSelectedItems } from "../filters/slice/filterSlice"
import { DataTable } from "./baseDataTable"
import { useSearchContactsQuery } from "./slice/apiSlice"

export function ContactsTable() {
  useDocumentTitle("Search People")
  const selectedFilters = useAppSelector(selectSelectedItems)
  const [pagination, setPagination] = useState({
    page: 1,
    count: 20,
    total: 0,
    lastPage: 1
  })
  const [queryValue, setQueryValue] = useState("")
  const [sortSelected, setSortSelected] = useState<string[]>([])

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({
      ...prev,
      page: newPage
    }))
  }

  const transformedFilters = useMemo(() => {
    const filterEntries = Object.entries(selectedFilters)
      .filter(([, values]) => values && values.length > 0)
      .map(([filterType, filterValues]) => ({
        type: filterType,
        values: filterValues.map((value) => ({
          id: value.id,
          text: value.name,
          selectionType: value.type === "include" ? "INCLUDED" : "EXCLUDED"
        }))
      }))
    return filterEntries
  }, [selectedFilters])

  const queryParams = useMemo(
    () => ({
      ...(queryValue && { searchTerm: queryValue }),
      ...(transformedFilters.length > 0 && { filters: transformedFilters })
    }),
    [queryValue, transformedFilters]
  )

  const searchParams = useMemo(
    () => ({
      page: pagination.page,
      count: pagination.count,
      ...(sortSelected.length && { sort: sortSelected.join(",") })
    }),
    [pagination.page, pagination.count, sortSelected]
  )

  const searchUrl = useMemo(() => buildSearchUrl(searchParams, queryParams), [searchParams, queryParams])

  const { data, isLoading, isFetching } = useSearchContactsQuery({ type: "contact", buildParams: searchUrl }, { refetchOnMountOrArgChange: true })

  const sortableFields = ["full_name", "website", "title", "linkedin_url", "company"]

  // Enhanced columns with better styling and icons
  const columns: DataTableColumn<ContactAttributes>[] = [
    {
      title: "Name",
      field: "full_name",
      width: "w-1/4",
      render: (value, row) => (
        <TooltipProvider>
          <div className="flex items-center gap-3">
            <Avatar className="flex size-6 shrink-0 items-center justify-center bg-primary/10">
              <User2 className="size-4 shrink-0 text-primary" />
            </Avatar>
            <div>
              {value ? <div className="text-sm font-normal">{value}</div> : <span className="text-sm text-muted-foreground">Not Available</span>}
              {!!row.email && <div className="text-xs text-muted-foreground">{row.email}</div>}
            </div>
            <Tooltip>
              <TooltipTrigger asChild>
                <a href={row.linkedin_url || "#"} target="_blank" rel="noopener noreferrer" className="flex items-center">
                  <LinkedinIcon className="size-4" />
                </a>
              </TooltipTrigger>
              <TooltipContent>
                <p>{row.linkedin_url?.replace("http://www.", "")}</p>
              </TooltipContent>
            </Tooltip>
          </div>
        </TooltipProvider>
      )
    },
    {
      title: "Title",
      field: "title",
      width: "w-1/5",
      render: (value) => <div className="flex items-center gap-2">{value || "N/A"}</div>
    },
    {
      title: "Company",
      field: "company",
      width: "w-1/5",
      render: (value) => (
        <div className="flex items-center gap-2">
          <Building2 className="size-4 shrink-0 text-muted-foreground" />
          <div>{value || <span className="text-sm text-muted-foreground">Not Available</span>}</div>
        </div>
      )
    },
    {
      title: "Website",
      field: "website",
      width: "w-1/5",
      render: (value) => (
        <a
          href={value ? `https://${value}` : "#"}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 text-primary hover:underline"
        >
          <Globe className="size-4 shrink-0" />
          <span>{value || <span className="text-sm text-muted-foreground">Not Available</span>}</span>
        </a>
      )
    }
  ]

  useEffect(() => {
    if (data?.meta) {
      setPagination((prev) => ({
        ...prev,
        total: data.meta.total || 0,
        lastPage: data.meta.last_page || 1
      }))
    }
  }, [data?.meta])

  return (
    <Card className="h-full border-gray-200 bg-white backdrop-blur-sm dark:border-gray-800 dark:bg-gray-950">
      <CardContent className="h-full p-6">
        <div className="flex h-full flex-col">
          {transformedFilters.length > 0 && (
            <div className="mb-4 flex flex-wrap gap-2">
              {transformedFilters.map((filter) =>
                filter.values.map((value) => (
                  <Badge key={value.id} variant={value.selectionType === "INCLUDED" ? "default" : "destructive"} className="text-xs">
                    {value.text}
                  </Badge>
                ))
              )}
            </div>
          )}
          <div className="flex-1">
            <DataTable<ContactAttributes>
              columns={columns}
              data={(data as SearchApiResponse<ContactAttributes>)?.data || []}
              loading={isLoading}
              fetching={isFetching}
              sortableFields={sortableFields}
              onSort={setSortSelected}
              sortSelected={sortSelected}
              searchPlaceholder="Search contacts..."
              onSearch={setQueryValue}
              searchValue={queryValue}
              pagination={pagination}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default ContactsTable
