import useDocumentTitle from "@/app/hooks/documentTitleHook"
import { useAppSelector } from "@/app/hooks/reduxHooks"
import { buildSearchUrl } from "@/app/utils/searchUtils"
import { Badge } from "@/components/ui/badge"
import { Card, CardContent } from "@/components/ui/card"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { DataTableColumn } from "@/interface/searchTable/components"
import { CompanyAttributes, SearchApiResponse } from "@/interface/searchTable/search"
import { Building2, Factory, Globe, MapPin } from "lucide-react"
import { useEffect, useMemo, useState } from "react"
import LinkedinIcon from "../../static/media/icons/linkedin_icon.svg?react"
import { selectSelectedItems } from "../filters/slice/filterSlice"
import { DataTable } from "./baseDataTable"
import { useSearchContactsQuery } from "./slice/apiSlice"

export function CompaniesTable() {
  useDocumentTitle("Search Companies")
  const selectedFilters = useAppSelector(selectSelectedItems)
  const [pagination, setPagination] = useState({
    page: 1,
    count: 20,
    total: 0,
    lastPage: 1
  })
  const [queryValue, setQueryValue] = useState("")
  const [sortSelected, setSortSelected] = useState<string[]>([])

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({
      ...prev,
      page: newPage
    }))
  }

  const transformedFilters = useMemo(() => {
    const filterEntries = Object.entries(selectedFilters)
      .filter(([, values]) => values && values.length > 0)
      .map(([filterType, filterValues]) => ({
        type: filterType,
        values: filterValues.map((value) => ({
          id: value.id,
          text: value.name,
          selectionType: value.type === "include" ? "INCLUDED" : "EXCLUDED"
        }))
      }))
    return filterEntries
  }, [selectedFilters])

  const queryParams = useMemo(
    () => ({
      ...(queryValue && { searchTerm: queryValue }),
      ...(transformedFilters.length > 0 && { filters: transformedFilters })
    }),
    [queryValue, transformedFilters]
  )

  const searchParams = useMemo(
    () => ({
      page: pagination.page,
      count: pagination.count,
      ...(sortSelected.length && { sort: sortSelected.join(",") })
    }),
    [pagination.page, pagination.count, sortSelected]
  )

  const searchUrl = useMemo(() => buildSearchUrl(searchParams, queryParams), [searchParams, queryParams])

  const { data, isLoading, isFetching } = useSearchContactsQuery({ type: "company", buildParams: searchUrl }, { refetchOnMountOrArgChange: true })

  const sortableFields = ["company", "website", "company_linkedin_url"]

  const columns: DataTableColumn<CompanyAttributes>[] = [
    {
      title: "Company",
      field: "company",
      width: "w-1/4",
      render: (value) => (
        <div className="flex items-center gap-2">
          <Building2 className="mt-1 size-4 shrink-0" />
          <span className="break-words">{value}</span>
        </div>
      )
    },
    {
      title: "Website",
      field: "website",
      width: "w-1/5",
      render: (value, row) => (
        <TooltipProvider>
          <div className="flex items-center gap-3">
            <Globe className="mt-1 size-4 shrink-0" />
            <span className="text-nowrap">{value}</span>
            <Tooltip>
              <TooltipTrigger asChild>
                <a href={row.company_linkedin_url || "#"} target="_blank" rel="noopener noreferrer" className="flex items-center">
                  <LinkedinIcon className="size-4" />
                </a>
              </TooltipTrigger>
              <TooltipContent>
                <p>{row.company_linkedin_url?.replace("http://www.", "")}</p>
              </TooltipContent>
            </Tooltip>
          </div>
        </TooltipProvider>
      )
    },
    {
      title: "Industry",
      field: "industry",
      width: "w-1/5",
      render: (value) => (
        <div className="flex items-center gap-2">
          <Factory className="mt-1 size-4 shrink-0" />
          {value}
        </div>
      )
    },
    {
      title: "Country",
      field: "location",
      width: "w-1/6",
      render: (value) => (
        <div className="flex items-center gap-2">
          <MapPin className="mt-1 size-4 shrink-0" />
          {value?.country || <span className="text-sm text-muted-foreground">Not Available</span>}
        </div>
      )
    }
  ]

  useEffect(() => {
    if (data?.meta) {
      setPagination((prev) => ({
        ...prev,
        total: data.meta.total || 0,
        lastPage: data.meta.last_page || 1
      }))
    }
  }, [data?.meta])

  return (
    <Card className="h-full border-gray-200 bg-white backdrop-blur-sm dark:border-gray-800 dark:bg-gray-950">
      <CardContent className="h-full p-6">
        <div className="flex h-full flex-col">
          {transformedFilters.length > 0 && (
            <div className="mb-4 flex flex-wrap gap-2">
              {transformedFilters.map((filter) =>
                filter.values.map((value) => (
                  <Badge key={value.id} variant={value.selectionType === "INCLUDED" ? "default" : "destructive"} className="text-xs">
                    {value.text}
                  </Badge>
                ))
              )}
            </div>
          )}
          <div className="flex-1">
            <DataTable<CompanyAttributes>
              columns={columns}
              data={(data as SearchApiResponse<CompanyAttributes>)?.data || []}
              loading={isLoading}
              fetching={isFetching}
              sortableFields={sortableFields}
              onSort={setSortSelected}
              sortSelected={sortSelected}
              searchPlaceholder="Search companies..."
              onSearch={setQueryValue}
              searchValue={queryValue}
              pagination={pagination}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default CompaniesTable
