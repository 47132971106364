import { Input } from "@/components/ui/input"
import { Skeleton } from "@/components/ui/skeleton"
import { LoadingSpinner } from "@/components/ui/spinner"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { DataTableColumn, DataTableProps } from "@/interface/searchTable/components"
import { ArrowDownIcon, ArrowUpDownIcon, ArrowUpIcon, Search } from "lucide-react"
import React from "react"
import Pagination from "./pagination"

export function DataTable<T>({
  columns,
  data,
  loading = false,
  fetching = false,
  sortableFields = [],
  onSort,
  sortSelected = [],
  searchPlaceholder = "Search...",
  onSearch,
  searchValue = "",
  pagination,
  onPageChange
}: DataTableProps<T>) {
  const renderCell = (column: DataTableColumn<T>, row: T): React.ReactNode => {
    const value = row[column.field]
    if (column.render) {
      return column.render(value, row)
    }
    return value as string
  }

  // Helper to get current sort direction for a column
  const getSortDirection = (field: string) => {
    const sortItem = sortSelected.find((item) => item.startsWith(field))
    if (!sortItem) return null
    return sortItem.endsWith(":asc") ? "asc" : "desc"
  }

  // Handle column header click for sorting
  const handleSort = (field: string) => {
    const currentDirection = getSortDirection(field)
    let newSort: string[]

    if (!currentDirection) {
      newSort = [`${field}:asc`]
    } else if (currentDirection === "asc") {
      newSort = [`${field}:desc`]
    } else {
      newSort = []
    }

    onSort?.(newSort)
  }

  if (loading) {
    return (
      <div className="w-full space-y-3">
        <div className="flex items-center justify-between">
          <Skeleton className="h-9 w-[300px]" />
        </div>
        <div className="rounded-lg border border-border/40 bg-background/50 backdrop-blur-sm">
          <Table>
            <TableHeader>
              <TableRow className="border-b border-border hover:bg-transparent">
                {columns.map((column) => (
                  <TableHead key={column.field as string} className={`${column.width} py-3`}>
                    <Skeleton className="h-5 mr-20" />
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {[...Array(20)].map((_, idx) => (
                <TableRow key={idx} className="border-b border-border/40 hover:bg-transparent">
                  {columns.map((column) => (
                    <TableCell key={column.field as string} className="py-2.5">
                      <Skeleton className="h-5 mr-20" />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Skeleton className="h-9 w-full" />
      </div>
    )
  }

  return (
    <div className="w-full space-y-5">
      <div className="flex items-center justify-between">
        {!!onSearch && (
          <div className="relative">
            <Search className="absolute left-2.5 top-2.5 z-10 size-4 text-muted-foreground" />
            <Input
              placeholder={searchPlaceholder}
              value={searchValue}
              onChange={(e) => onSearch(e.target.value)}
              className="h-9 w-[400px] bg-background/50 px-9 backdrop-blur-sm"
            />
            {fetching && <LoadingSpinner size="sm" className="absolute right-2.5 top-2.5 z-10 size-4" />}
          </div>
        )}
      </div>

      <div className={`relative bg-background/50 backdrop-blur-sm transition-all duration-200 ${fetching ? "opacity-50" : ""}`}>
        {fetching && (
          <div className="absolute inset-0 z-10 flex items-center justify-center bg-background/30">
            <LoadingSpinner size="lg" />
          </div>
        )}
        <Table>
          <TableHeader>
            <TableRow className="border-b border-border hover:bg-transparent">
              {columns.map((column) => {
                const sortDirection = getSortDirection(column.field as string)
                const isSortable = sortableFields.some((opt) => opt === (column.field as string))

                return (
                  <TableHead key={column.field as string} className={`${column.width} py-3 text-xs font-medium text-muted-foreground`}>
                    <div
                      className={`flex items-center gap-2 ${isSortable && !fetching ? "group cursor-pointer" : ""} ${
                        fetching ? "pointer-events-none" : ""
                      }`}
                      onClick={() => isSortable && !fetching && handleSort(column.field as string)}
                    >
                      {column.title}
                      {!!isSortable && (
                        <div className="transition-opacity">
                          {sortDirection === "asc" ? (
                            <ArrowUpIcon className="size-4" />
                          ) : sortDirection === "desc" ? (
                            <ArrowDownIcon className="size-4" />
                          ) : (
                            <ArrowUpDownIcon className="size-[14px] text-muted-foreground/80 group-hover:text-muted-foreground" />
                          )}
                        </div>
                      )}
                    </div>
                  </TableHead>
                )
              })}
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-32 text-center">
                  <div className="flex flex-col items-center justify-center gap-2">
                    <Search className="size-8 text-muted-foreground/80" />
                    <p className="text-sm text-muted-foreground">No results found</p>
                    {searchValue && <p className="text-xs text-muted-foreground/80">Try adjusting your search or filters</p>}
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              data.map((row, rowIndex) => (
                <TableRow key={row.id || rowIndex} className="border-b border-border/40 transition-colors hover:bg-muted/40">
                  {columns.map((column) => (
                    <TableCell key={column.field as string} className="py-2.5 text-sm">
                      {renderCell(column, row.attributes)}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      {!!pagination && (
        <Pagination
          currentPage={pagination.page}
          lastPage={pagination.lastPage}
          onPageChange={onPageChange}
          className={`transition-opacity duration-200 ${fetching ? "opacity-50" : ""}`}
        />
      )}
    </div>
  )
}

export default DataTable
