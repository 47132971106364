import { ACCOUNT_HOST } from "@/app/constants/apiConstants"
import { useAppDispatch, useAppSelector } from "@/app/hooks/reduxHooks"
import { Button } from "@/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { useLogoutMutation } from "@/features/settings/slice/apiSlice"
import { clearUserSession, selectTheme, selectUser, setTheme } from "@/features/settings/slice/settingSlice"
import { TNonNullUserState } from "@/interface/settings/selectors"
import { TThemeMode } from "@/interface/settings/slice"
import { Code2, LogOut, LucideIcon, Monitor, Moon, ScrollText, Search, Sun, User, User2 } from "lucide-react"
import { FC, useEffect } from "react"
import { Link } from "react-router-dom"
import { LoadingSpinner } from "../ui/spinner"
import NavAnchor from "../utils/navAnchor"
import LacleoLogo from "./../../static/media/logo/lacleo_logo.svg?react"

const themes: Array<{ value: TThemeMode; label: string; icon: LucideIcon; description: string }> = [
  {
    value: "system",
    label: "System",
    icon: Monitor,
    description: "Follow system theme"
  },
  {
    value: "light",
    label: "Light",
    icon: Sun,
    description: "Bright and clear"
  },
  {
    value: "dark",
    label: "Dark",
    icon: Moon,
    description: "Easy on the eyes"
  }
]

interface ThemeSwitcherProps {
  themeMode: TThemeMode
  onThemeChange: (theme: TThemeMode) => void
}

const ThemeSwitcher: FC<ThemeSwitcherProps> = ({ themeMode, onThemeChange }) => {
  const ThemeIcon: FC<{ mode: TThemeMode }> = ({ mode }) => {
    const theme = themes.find((t) => t.value === mode)
    const Icon = theme?.icon || Monitor
    return <Icon className="size-4" />
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="gap-0 hover:bg-accent dark:hover:bg-accent" aria-label="Toggle theme">
          <ThemeIcon mode={themeMode} />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-64">
        {themes.map(({ value, label, icon: Icon, description }) => (
          <DropdownMenuItem key={value} className="flex cursor-pointer items-center px-4 py-2 focus:bg-accent" onClick={() => onThemeChange(value)}>
            <div className="flex items-center space-x-3">
              <div className={`rounded-md p-1 ${themeMode === value ? "bg-accent" : ""}`}>
                <Icon className="size-4" />
              </div>
              <div className="space-y-1">
                <p className="text-sm font-medium leading-none">{label}</p>
                <p className="text-xs text-muted-foreground">{description}</p>
              </div>
            </div>
            {themeMode === value && (
              <span className="absolute right-4 flex size-3 items-center justify-center">
                <span className="size-2 rounded-full bg-primary"></span>
              </span>
            )}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const AppHeader = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser) as TNonNullUserState
  const themeMode = useAppSelector(selectTheme)
  const [triggerMutation, { isError, isLoading, data }] = useLogoutMutation()

  const onThemeChange = (value: TThemeMode) => {
    dispatch(setTheme(value))
  }

  useEffect(() => {
    if (!isLoading && (data || isError)) {
      dispatch(clearUserSession())
      window.location.href = ACCOUNT_HOST
    }
  }, [isError, data, isLoading, dispatch])

  return (
    <header className="fixed top-0 z-50 w-full border-b border-border backdrop-blur supports-[backdrop-filter]:bg-white dark:bg-gray-900">
      <div className="flex h-[64px] items-center px-10">
        <NavAnchor to="/app" className="flex items-center gap-2 p-2">
          <LacleoLogo className="w-[115px] dark:invert" />
        </NavAnchor>
        <div className="ml-6 flex flex-1 items-center justify-between">
          {/* Logo and Navigation */}
          <nav className="flex items-center space-x-6">
            <div className="hidden gap-4 md:flex md:items-center">
              <NavAnchor
                to="/app/search"
                activePaths={["/app/search/*"]}
                activeClassName="group inline-flex items-center gap-2 rounded-md px-4 py-2 text-sm font-medium text-gray-900 dark:text-white bg-gray-100 bg-background dark:bg-gray-800 shadow-sm ring-1 ring-gray-200 dark:ring-gray-700 transition-all"
                className="group inline-flex items-center gap-2 rounded-lg px-4 py-2 text-sm font-medium text-gray-600 transition-all hover:text-foreground dark:text-gray-400"
              >
                <Search className="size-4 group-[.font-medium]:stroke-[2.5]" /> Search
              </NavAnchor>
              <NavAnchor
                to="/app/lists"
                activeClassName="group inline-flex items-center gap-2 rounded-md px-4 py-2 text-sm font-medium text-gray-900 dark:text-white bg-gray-100 bg-background dark:bg-gray-800 shadow-sm ring-1 ring-gray-200 dark:ring-gray-700 transition-all"
                className="group inline-flex items-center gap-2 rounded-lg px-4 py-2 text-sm font-medium text-gray-600 transition-all hover:text-foreground dark:text-gray-400"
                activePaths={["/app/lists"]}
              >
                <ScrollText className="size-4 group-[.font-medium]:stroke-[2.5]" /> Lists
              </NavAnchor>
              <NavAnchor
                to="/app/api"
                activePaths={["/app/api"]}
                activeClassName="group inline-flex items-center gap-2 rounded-md px-4 py-2 text-sm font-medium text-gray-900 dark:text-white bg-gray-100 bg-background dark:bg-gray-800 shadow-sm ring-1 ring-gray-200 dark:ring-gray-700 transition-all"
                className="group inline-flex items-center gap-2 rounded-lg px-4 py-2 text-sm font-medium text-gray-600 transition-all hover:text-foreground dark:text-gray-400"
              >
                <Code2 className="size-4 group-[.font-medium]:font-medium" /> API
              </NavAnchor>
            </div>
          </nav>

          {/* User Menu */}
          <div className="flex items-center space-x-4">
            <ThemeSwitcher themeMode={themeMode} onThemeChange={onThemeChange} />
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative size-8 rounded-full p-0">
                  {user.profile_photo_url ? (
                    <img className="size-8 rounded-full object-cover" src={user.profile_photo_url} alt={user.name} />
                  ) : (
                    <User className="size-4" />
                  )}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-56">
                <div className="flex items-center justify-start gap-2 p-2">
                  <div className="flex flex-col space-y-1">
                    <span className="mb-1 block text-sm text-foreground/60">Signed in as</span>
                    <p className="text-sm font-medium leading-none">{user.name}</p>
                    <p className="text-xs leading-none text-muted-foreground">{user.email}</p>
                  </div>
                </div>
                <DropdownMenuSeparator />
                <DropdownMenuItem asChild>
                  <Link to={`${ACCOUNT_HOST}/user/profile`} className="flex w-full cursor-pointer items-center">
                    <User2 className="mr-2 size-4" />
                    <span>Profile</span>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => triggerMutation()} className="text-red-600 focus:text-red-600">
                  {isLoading ? <LoadingSpinner size="sm" /> : <LogOut className="mr-2 size-4" />}
                  <span>Log out</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </header>
  )
}

export default AppHeader
