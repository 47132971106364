// features/filters/components/Filters.tsx
import { useFilterSearch } from "@/app/hooks/filterValuesSearchHook"
import { useAppDispatch, useAppSelector } from "@/app/hooks/reduxHooks"
import { Card } from "@/components/ui/card"
import NavAnchor from "@/components/utils/navAnchor"
import { IFilter, IFilterGroup } from "@/interface/filters/filterGroup"
import { SelectedFilter } from "@/interface/filters/slice"
import { Building2, CheckCircle, ChevronDown, ChevronUp, Search, UsersRound } from "lucide-react"
import { useEffect, useMemo } from "react"
import { useLocation } from "react-router-dom"
import FilterSearchValueResults from "./filterValues"
import { useGetFiltersQuery } from "./slice/apiSlice"
import {
  addSelectedItem,
  removeSelectedItem,
  resetFilters,
  selectExpandedSections,
  selectSearchTerms,
  selectSelectedItems,
  setSearchTerm,
  toggleSection
} from "./slice/filterSlice"

const FilterTag = ({ item, onRemove }: { item: SelectedFilter; onRemove: () => void }) => (
  <span
    className={`inline-flex items-center gap-1.5 rounded-full px-3 py-1 text-sm font-medium transition-colors ${
      item.type === "include"
        ? "bg-emerald-50 text-emerald-700 ring-1 ring-emerald-200/50 dark:bg-emerald-950/50 dark:text-emerald-400 dark:ring-emerald-800/50"
        : "bg-red-50 text-red-700 ring-1 ring-red-200/50 dark:bg-red-950/50 dark:text-red-400 dark:ring-red-800/50"
    }`}
  >
    {item.name}
    <button onClick={onRemove} className="rounded-full p-0.5 transition-colors hover:bg-black/10 dark:hover:bg-white/10" aria-label="Remove filter">
      <svg className="size-3.5" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  </span>
)

const SectionHeader = ({ title, expanded, onClick }: { title: string; expanded: boolean; onClick: () => void }) => (
  <button
    onClick={onClick}
    className="group flex w-full items-center justify-between px-4 py-3.5 transition-colors hover:bg-gray-50/50 dark:hover:bg-gray-900/50"
  >
    <span className="text-sm font-normal text-gray-700 dark:text-gray-200">{title}</span>
    <span className="text-gray-400 dark:text-gray-500">{expanded ? <ChevronUp className="size-4" /> : <ChevronDown className="size-4" />}</span>
  </button>
)

export const Filters = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const isPeoplePage = location.pathname === "/app/search/contacts"

  const expandedSections = useAppSelector(selectExpandedSections)
  const searchTerms = useAppSelector(selectSearchTerms)
  const selectedItems = useAppSelector(selectSelectedItems)

  const { currentData: filterGroups = [] } = useGetFiltersQuery()
  const { searchState, handleSearch, clearSearchResults } = useFilterSearch()

  const handleSearchChange = async (filter: IFilter, term: string) => {
    dispatch(setSearchTerm({ sectionId: filter.id, term }))
    if (term) {
      await handleSearch(filter, term)
    } else {
      clearSearchResults(filter.id)
    }
  }

  const currentFiltersGroups: IFilterGroup[] = useMemo(
    () =>
      filterGroups
        .map((group) => ({ ...group, filters: group.filters.filter((filter) => filter.filter_type === (isPeoplePage ? "contact" : "company")) }))
        .filter((group) => group.filters.length > 0),
    [filterGroups, isPeoplePage]
  )

  useEffect(() => {
    dispatch(resetFilters())
  }, [dispatch, location.pathname])

  useEffect(() => {
    const filters = currentFiltersGroups.flatMap((group) => group.filters).filter((filter) => filter.input_type !== "text")
    Object.entries(expandedSections).forEach(([filterId, isExpanded]) => {
      const filter = filters.find((filter) => filter.id === filterId)
      if (isExpanded && filter) handleSearch(filter)
    })
  }, [expandedSections, currentFiltersGroups, handleSearch])

  return (
    <Card className="min-h-full min-w-full overflow-hidden rounded-none bg-background dark:border-gray-800 dark:bg-gray-950">
      <div className="relative border-b border-transparent bg-white px-4 dark:border-gray-800 dark:bg-background dark:from-gray-950 dark:to-gray-900/50">
        <nav className="flex space-x-3 pt-2">
          <NavAnchor
            to="contacts"
            activePaths={["/app/search/contacts"]}
            activeClassName="group py-4 gap-2 space-x-2 border-b-2 px-1 text-sm inline-flex items-center border-primary text-primary font-semibold"
            className="group inline-flex items-center gap-2 space-x-2 border-b-2 border-transparent px-1 py-4 text-sm font-normal text-muted-foreground hover:text-foreground"
          >
            <UsersRound className="size-4 group-[.font-semibold]:stroke-[2.5]" /> People
          </NavAnchor>
          <NavAnchor
            to="companies"
            activePaths={["/app/search/companies"]}
            activeClassName="group py-4 gap-2 space-x-2 border-b-2 px-1 text-sm inline-flex items-center border-primary text-primary font-semibold"
            className="group inline-flex items-center gap-2 space-x-2 border-b-2 border-transparent px-1 py-4 text-sm font-normal text-muted-foreground hover:text-foreground"
          >
            <Building2 className="size-4 group-[.font-semibold]:stroke-[2.5]" /> Company
          </NavAnchor>
        </nav>
      </div>

      {currentFiltersGroups.map((group) => (
        <div key={group.group_name}>
          <div className="px-4 py-3">
            <h2 className="font-semibold text-gray-600 dark:text-gray-400">{group.group_name}</h2>
          </div>
          <Card className="overflow-hidden rounded-none border-0 bg-white shadow-none dark:bg-gray-800/80">
            {group.filters
              .filter((filter) => filter.filter_type === (isPeoplePage ? "contact" : "company"))
              .map((filter) => (
                <div key={filter.id} className="border-t border-gray-100 first:border-0 dark:border-gray-800/50">
                  <SectionHeader title={filter.name} expanded={expandedSections[filter.id]} onClick={() => dispatch(toggleSection(filter.id))} />
                  {!!expandedSections[filter.id] && (
                    <div className="space-y-3 p-4">
                      {filter.input_type === "text" ? (
                        <form
                          className="relative"
                          onSubmit={(e) => {
                            e.preventDefault()
                            dispatch(
                              addSelectedItem({
                                sectionId: filter.id,
                                item: { id: searchTerms[filter.id] || "", name: searchTerms[filter.id] || "", type: "include" }
                              })
                            )
                          }}
                        >
                          <input
                            type="text"
                            className="w-full rounded-lg border border-gray-200 bg-white py-2 pl-2 pr-9 text-sm text-gray-900 transition-shadow placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:border-gray-800 dark:bg-gray-900 dark:text-gray-100 dark:placeholder:text-gray-500 dark:focus:ring-gray-700"
                            placeholder={`Search ${filter.name.toLowerCase()}`}
                            value={searchTerms[filter.id] || ""}
                            onChange={(e) => handleSearchChange(filter, e.target.value)}
                          />
                          <button type="submit">
                            <CheckCircle className="absolute right-3 top-2.5 size-4 cursor-pointer text-green-400 dark:text-green-500" />
                          </button>
                        </form>
                      ) : filter.is_searchable ? (
                        <div className="relative">
                          <Search className="absolute left-3 top-2.5 size-4 text-gray-400 dark:text-gray-500" />
                          <input
                            type="text"
                            className="w-full rounded-lg border border-gray-200 bg-white py-2 pl-9 text-sm text-gray-900 transition-shadow placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:border-gray-800 dark:bg-gray-900 dark:text-gray-100 dark:placeholder:text-gray-500 dark:focus:ring-gray-700"
                            placeholder={`Search ${filter.name.toLowerCase()}`}
                            value={searchTerms[filter.id] || ""}
                            onChange={(e) => handleSearchChange(filter, e.target.value)}
                          />
                        </div>
                      ) : null}
                      {selectedItems[filter.id]?.length > 0 && (
                        <div className="flex flex-wrap gap-2">
                          {selectedItems[filter.id].map((item) => (
                            <FilterTag
                              key={item.id}
                              item={item}
                              onRemove={() => dispatch(removeSelectedItem({ sectionId: filter.id, itemId: item.id }))}
                            />
                          ))}
                        </div>
                      )}

                      {filter.input_type !== "text" && (
                        <div className="mt-2">
                          <FilterSearchValueResults
                            results={searchState.results[filter.id]}
                            metadata={searchState.metadata[filter.id]}
                            isLoading={searchState.isLoading[filter.id]}
                            error={searchState.error[filter.id]}
                            selectedItems={selectedItems[filter.id] || []}
                            canExclude={filter.allows_exclusion}
                            onInclude={(item) =>
                              dispatch(
                                addSelectedItem({
                                  sectionId: filter.id,
                                  item: { ...item, type: "include" }
                                })
                              )
                            }
                            onExclude={(item) =>
                              dispatch(
                                addSelectedItem({
                                  sectionId: filter.id,
                                  item: { ...item, type: "exclude" }
                                })
                              )
                            }
                            onRemove={(item) => dispatch(removeSelectedItem({ sectionId: filter.id, itemId: item.id }))}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </Card>
        </div>
      ))}
    </Card>
  )
}

export default Filters
