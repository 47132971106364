import { apiSlice } from "@/app/redux/apiSlice"
import { CompanyAttributes, ContactAttributes, SearchApiResponse, SearchRequestParams } from "@/interface/searchTable/search"

const contactsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchContacts: builder.query<SearchApiResponse<ContactAttributes | CompanyAttributes>, SearchRequestParams>({
      query: ({ type, buildParams }) => ({ url: `/search/${type}${buildParams}` }),
      transformResponse: (response: SearchApiResponse<ContactAttributes | CompanyAttributes>, meta, arg) => {
        if (arg.type === "contact") {
          return response as SearchApiResponse<ContactAttributes>
        }
        return response as SearchApiResponse<CompanyAttributes>
      }
    })
  })
})

export const { useSearchContactsQuery } = contactsApi
