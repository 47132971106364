import { FilterState, SelectedFilter } from "@/interface/filters/slice"
import { TRootState } from "@/interface/reduxRoot/state"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

const initialState: FilterState = {
  expandedSections: {},
  searchTerms: {},
  selectedItems: {}
}

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    toggleSection: (state, action: PayloadAction<string>) => {
      const sectionId = action.payload
      state.expandedSections[sectionId] = !state.expandedSections[sectionId]
    },
    setSearchTerm: (state, action: PayloadAction<{ sectionId: string; term: string }>) => {
      const { sectionId, term } = action.payload
      state.searchTerms[sectionId] = term
    },
    addSelectedItem: (state, action: PayloadAction<{ sectionId: string; item: SelectedFilter }>) => {
      const { sectionId, item } = action.payload

      if (!state.selectedItems[sectionId]) {
        state.selectedItems[sectionId] = []
      }

      const existingItemIndex = state.selectedItems[sectionId].findIndex((existingItem) => existingItem.id === item.id)
      if (existingItemIndex !== -1) {
        // If item exists but with different type, update its type
        if (state.selectedItems[sectionId][existingItemIndex].type !== item.type) {
          state.selectedItems[sectionId][existingItemIndex].type = item.type
        }
        // If same type, do nothing (already selected)
      } else {
        // If item doesn't exist, add it
        state.selectedItems[sectionId].push(item)
      }
    },
    removeSelectedItem: (state, action: PayloadAction<{ sectionId: string; itemId: string }>) => {
      const { sectionId, itemId } = action.payload
      if (state.selectedItems[sectionId]) {
        state.selectedItems[sectionId] = state.selectedItems[sectionId].filter((item) => item.id !== itemId)
      }
    },
    resetFilters: () => initialState
  }
})

export const { toggleSection, setSearchTerm, addSelectedItem, removeSelectedItem, resetFilters } = filterSlice.actions

export const selectExpandedSections = (state: TRootState) => state.filters.expandedSections
export const selectSearchTerms = (state: TRootState) => state.filters.searchTerms
export const selectSelectedItems = (state: TRootState) => state.filters.selectedItems

export default filterSlice.reducer
