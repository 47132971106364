/* eslint-disable @typescript-eslint/no-unused-vars */
import { transformErrorResponse, USER } from "@/app/constants/apiConstants"
import { accountBaseQuery, apiSlice } from "@/app/redux/apiSlice"
import { IUser } from "@/interface/settings/slice"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"

export const authSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<IUser, void>({
      query: () => ({ url: USER.GET_USER }),
      transformResponse: (response: { data: IUser }) => response.data,
      transformErrorResponse,
      keepUnusedDataFor: 0
    }),
    logout: builder.mutation<string, void>({
      queryFn: async (_arg, queryApi, extraOptions, _baseQuery) => {
        try {
          const result = await accountBaseQuery({ url: USER.LOGOUT_USER, method: "POST" }, queryApi, extraOptions)
          if (result.error) {
            return { error: result.error }
          }

          return { data: "success" }
        } catch (error) {
          return {
            error: {
              status: 500,
              data: error instanceof Error ? error.message : "Unknown error"
            } as FetchBaseQueryError
          }
        }
      }
    })
  })
})

export const { useLazyGetUserQuery, useLogoutMutation } = authSlice
