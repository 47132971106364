import alertsSliceReducer from "@/features/alerts/slice/alertSlice"
import filtersSliceReducer from "@/features/filters/slice/filterSlice"
import settingSliceReducer from "@/features/settings/slice/settingSlice"
import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./apiSlice"

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    setting: settingSliceReducer,
    alerts: alertsSliceReducer,
    filters: filtersSliceReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true
})
