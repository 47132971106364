import Filters from "@/features/filters/filterSection"
import { Outlet } from "react-router-dom"

const SearchLayout = () => {
  return (
    <div className="flex min-h-[calc(100vh-64px)]">
      <aside className="fixed bottom-0 top-16 w-96 shrink-0 overflow-y-auto">
        <Filters />
      </aside>
      <div className="ml-96 mt-[64px] flex-1">
        <div className="h-full p-6">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default SearchLayout
