import { ACCOUNT_HOST } from "@/app/constants/apiConstants"
import { useAlert } from "@/app/hooks/alertHooks"
import { useAppDispatch, useAppSelector } from "@/app/hooks/reduxHooks"
import { useLazyGetUserQuery } from "@/features/settings/slice/apiSlice"
import { selectToken, setUser } from "@/features/settings/slice/settingSlice"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import AppHeader from "../layout/appHeader"
import { Alert, AlertDescription, AlertTitle, ANIMATION_VARIANTS } from "../ui/alert"
import { LoadingSpinner } from "../ui/spinner"

const RequireAuth = () => {
  const dispatch = useAppDispatch()
  const { showAlert } = useAlert()
  const token = useAppSelector(selectToken)
  const [isInitialized, setIsInitialized] = useState(false)
  const [triggerApi, { isError, currentData, isFetching }] = useLazyGetUserQuery()

  const redirectToLogin = () => (window.location.href = ACCOUNT_HOST)

  useEffect(() => {
    if (!isFetching && isError) {
      showAlert("Session Expired", "For your security, please sign in again to continue. Redirecting you to the login page...", "warning")
      const redirectTimer = setTimeout(redirectToLogin, 1500)
      return () => clearTimeout(redirectTimer)
    }
  }, [showAlert, isFetching, isError])

  useEffect(() => {
    if (!isFetching && currentData) {
      dispatch(setUser(currentData))
      setIsInitialized(true)
    }
  }, [isFetching, currentData, dispatch])

  useEffect(() => {
    if (!token) {
      redirectToLogin()
      return
    }
    triggerApi()
  }, [token, triggerApi])

  if (!token || isError) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-background">
        <motion.div layout variants={ANIMATION_VARIANTS.alert} initial="initial" animate="animate" exit="exit">
          <Alert variant="info" className="relative flex min-w-96 items-center overflow-hidden shadow-lg dark:shadow-lg">
            <LoadingSpinner size="default" className="mr-3 text-blue-800 dark:text-blue-300" />
            <div>
              <AlertTitle className="mb-1 text-base font-semibold">Preparing Your Secure Login</AlertTitle>
              <AlertDescription className="text-sm text-muted-foreground">Just a moment while we redirect you to the login page...</AlertDescription>
            </div>
          </Alert>
        </motion.div>
      </div>
    )
  }

  if (isFetching || !isInitialized) {
    return (
      <div className="flex min-h-screen flex-col items-center justify-center gap-4 bg-background">
        <LoadingSpinner size="lg" className="text-primary" />
        <div className="text-center">
          <p className="mb-1 text-lg font-medium">Verifying Your Identity</p>
          <p className="text-sm text-muted-foreground">Please wait while we ensure a secure connection...</p>
        </div>
      </div>
    )
  }

  return (
    <div className="flex h-screen flex-col">
      <AppHeader />
      <main className="flex-1">
        <Outlet />
      </main>
    </div>
  )
}

export default RequireAuth
