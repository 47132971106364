import { TRootState } from "@/interface/reduxRoot/state"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import Cookies from "js-cookie"
import { ACCOUNT_HOST } from "../constants/apiConstants"

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_HOST as string,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as TRootState
    const token = state.setting.token
    if (token) headers.set("authorization", `Bearer ${token}`)
    return headers
  }
})

export const accountBaseQuery = fetchBaseQuery({
  baseUrl: ACCOUNT_HOST as string,
  credentials: "include",
  prepareHeaders: (headers) => {
    const xsrfToken = Cookies.get("XSRF-TOKEN")
    if (xsrfToken) {
      headers.set("X-XSRF-TOKEN", xsrfToken)
    }

    return headers
  }
})

export const apiSlice = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({})
})
